import type { RouteLocationNormalized } from 'vue-router';
import { useCartStore } from '~/stores/cartStore';

export default defineNuxtRouteMiddleware((to: RouteLocationNormalized) => {
  const cartStore = useCartStore();
  if (to.name === '/driver-details' && cartStore.selectedCar?.meta?.isLead) {
    return '/';
  }

  if (to.path === '/subscription/customization' && !cartStore.selectedCar?.id) {
    return '/subscription/catalog';
  }
});
